import { useState } from 'react';
import { AssessmentContentDto } from '../ApiClient';
import { NextContentType } from '../types';
import { check } from './AnswerChecker';
import { playCorrect } from '../SoundEffects';

type AssessmentProps = {
    content: AssessmentContentDto,
    onNext: NextContentType
  }

export function Assessment({ content, onNext }: AssessmentProps) {
  const [state, setState] = useState('')
  const [answer, setAnswer] = useState('')

  const skipResult = () => {
    return {
      isCorrect: false,
      isSkipped: true,
      isDontKnow: false
    }
  }

  const tellMeResult = () => {
    return {
      isCorrect: false,
      isSkipped: false,
      isDontKnow: true
    }
  }

  const renderAnswer = () => {
    const checkResult = check(content.correctAnswer, answer);
    let isCorrect =  checkResult.match;

    if (isCorrect) {
      void playCorrect()
    }

    let result = {
      isCorrect: isCorrect,
      isSkipped: false,
      isDontKnow: false
    }

    let text = isCorrect
      ? 'Correct! 🎉'
      : 'Oops, the correct answer is: '  + content.correctAnswer;

    const payAttentionToAccents = (
      <>
        <p>Pay attention to accents.</p>
        <p>You answered: {answer}</p>
        <p>Correct answer: {content.correctAnswer}</p>
      </>
    );

    return (
      <div onClick={() => onNext(result)} style={{ cursor: 'pointer' }}>
          <p
            style={{
              padding: 25,
              fontSize: 24,
              textAlign: 'center'
            }}
          >
            {text}
          </p>
          { checkResult.differentAccentsOnly ? payAttentionToAccents : '' }
      </div>
    );
  }

  const renderTellMe = () => {
    return (
      <div>
        <p
          style={{
            padding: 25,
            fontSize: 24,
            textAlign: 'center'
          }}
        >
          The correct answer is: {content.correctAnswer}
        </p>
        <p onClick={() => onNext(tellMeResult())}>OK</p>
        {/* <Button title="OK" onPress={() => onNext(tellMeResult())} /> */}
      </div>
    );
  }

//   const answerKeyPress = (key) => {
//     if (key === 'Enter') {
//       setState('submit_answer');
//     }
//   }

  const renderAssesment = () => {
    return (
        <div className="assessment-flex">
            <div className="assessment-content">
                <form>
                    <p>{content.prompt}</p>
                    <input
                        type="text"
                        id="answer"
                        name="answer"
                        autoComplete="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        // autoFocus=""
                        onChange={newAnswer => setAnswer(newAnswer.target.value)}
                        />
                    <input type="submit" name="submitAnswer" value="Submit Answer" onClick={(e) => {
                        e.preventDefault();
                        setState('submit_answer') }} />
                    <input type="submit" name="tellMe" value="Tell me" onClick={(e) => {
                        e.preventDefault();
                        setState('tell_me')
                    }} />
                    <input type="submit" name="skip" value="Skip" onClick={(e) => {
                        e.preventDefault();
                        onNext(skipResult())
                    }}/>
                </form>
            </div>
        </div>);
  }

  const render = () => {
    if (state === 'tell_me') {
      return renderTellMe();
    } else if (state === 'submit_answer') {
      return renderAnswer();
    } else {
      return renderAssesment();
    }
  }

  return render();
}
