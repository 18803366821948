import { theme } from './Theme';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommunityCreateRequestDto } from './ApiClientGenerated';
import { api } from './ApiClientFactory'

export function NewCommunity() {
  const [name, setName] = React.useState('');

  let navigate = useNavigate();

  async function create() {
    await api.createCommunity(new CommunityCreateRequestDto({name}));
    navigate(-1);
  }

  return (
    <div style={theme.screen}>
      <h1>New Community</h1>
      <form>
        <input
          type='text'
          placeholder="Community Name"
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <input type='button' value="Submit" onClick={create} />
        </div>
      </form>
    </div>
  );
}
