import { apiBaseUrl } from './ConfigService';
import { AdaApiClient } from './ApiClientGenerated';

const adaHttp = {
  fetch: function adaFetch(url: RequestInfo, init?: RequestInit) {
    if (init != null) {
      init.credentials = 'include';
    }
    return fetch(url, init);
  }
}

function cononicalBaseUrl() {
  var result = apiBaseUrl();
  if (result.endsWith('/')) {
    result = result.substring(0, result.length - 1);
  }
  return result;
}

export const api = createApiClient();

export function createApiClient() {
  return new AdaApiClient(cononicalBaseUrl(), adaHttp);
}
