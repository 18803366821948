interface AnswerCheckResult  {
  exactMatch: boolean
  match: boolean
  differentAccentsOnly: boolean
}

// Use Unicode property escapes to replace Punctuation and Symbols
// Diacritic characters are glyphs added to letter glyphs (basically, accent glyphs)
const punctuation = new RegExp(/\p{Punctuation}/gu);
const symbols = new RegExp(/\p{Symbol}/gu);
const diacritic = new RegExp(/\p{Diacritic}/gu);

export const check = (correctAnswer: string, providedAnswer: string) : AnswerCheckResult => {
    const exactMatch = correctAnswer === providedAnswer;
    const match = cononicalize(correctAnswer) === cononicalize(providedAnswer);

    const differentAccentsOnly =
        match
        && cononicalize(correctAnswer, true) !== cononicalize(providedAnswer, true);

    return {
        exactMatch,
        match,
        differentAccentsOnly
    }
}

function cononicalize(s: string, preserveAccents: boolean = false): string | null {
if (s === undefined || s == null) {
    return null;
}

let result = s
    .replace(punctuation, '')
    .replace(symbols, '')
    ?.trim()
    // Future: should use locale-aware toLowerCase()
    ?.toLowerCase();

if (!preserveAccents) {
    result = result
    // Expand the string so characters like ñ become n~
    // We may in the future want to use "NFKD" if we want things like ﬁ normalized to fi
    .normalize('NFD')
    // Now remove the diacratic characters (basically, accent glyphs)
    .replace(diacritic, '')
}

return result;
}
  