import { useParams } from "react-router-dom";
import { CommunityDto, CommunitySummaryDto, CommunityUpdateDto, SamlSettingsUpdateDto, SamlSettingsDto, DomainClaimRequestDto } from "./ApiClientGenerated";
import { useCallback, useEffect, useState } from "react";
import { api } from './ApiClientFactory'

export function CommunityEditPage() {
  const [community, setCommunity] = useState<CommunityDto>()
  const [domainClaims, setDomainClaims] = useState<DomainClaimRequestDto[]>()
  const [saml, setSaml] = useState<SamlSettingsDto>()

  let { communityId } = useParams();

  const load = useCallback(async function load() : Promise<{ community: CommunitySummaryDto, claims: DomainClaimRequestDto[], saml: SamlSettingsDto }> {
    const community = await api.showCommunity(communityId!); // TODO: get rid of "!"
    const claims = await api.listDomainClaimRequests(community.community.id);
    const saml = await api.showCommunitySamlSettings(community.community.id);
    return { community, claims, saml };
  }, [communityId])

  useEffect(() => {
    load().then((result) => {
      setCommunity(result.community.community)
      setDomainClaims(result.claims)
      setSaml(result.saml)
    })
  }, [load])

  function deleteClaim(claimId?: string) {
    // FUTURE: actually delete claim, or claim request
    // ASP.Net Core version did not implement this
    console.log('delete claim ' + claimId);
  }

  function renderClaim(claim: DomainClaimRequestDto) {
    const pending = !claim.isApproved ? "(Pending)" : "";
    var trashIcon = (
      <span
        // href='#'
        style={{ color: '#1C1C1C' }}
        onClick={(e) => {
          e.preventDefault();
          deleteClaim(claim.claimId);
        }}
      >
        <i className='fa fa-trash' aria-hidden='true' data-claim-id={claim.claimId}></i>
      </span>)
    return <li key={claim.claimId ?? claim.requestId}>{claim.domain} {pending} {trashIcon}</li>
  }

  async function saveSaml() {
    var updates = new SamlSettingsUpdateDto({
      cert: saml!.cert,
      communityId: saml!.communityId,
      endpoint: saml!.endpoint,
      forceAuthn: saml!.forceAuthn
    });
    await api.updateCommunitySamlSettings(saml!.communityId, updates);
  }

  async function saveCommunity() {
    var updates = new CommunityUpdateDto({
      name: community!.name,

      slug: community!.slug
    });
    await api.updateCommunity(communityId!, updates);
    window.location.href = '/communities/' + community!.slug // new slug
  }

  function certChanged(cert: string) {
    setSaml(new SamlSettingsDto({
      communityId: saml!.communityId,
      cert: cert,
      endpoint: saml!.endpoint,
      forceAuthn: saml!.forceAuthn
    }))
  }

  function endpointChanged(endpoint: string) {
    setSaml(new SamlSettingsDto({
      communityId: saml!.communityId,
      cert: saml!.cert,
      endpoint: endpoint,
      forceAuthn: saml!.forceAuthn
    }))
  }

  function forceAuthnChanged() {
    setSaml(new SamlSettingsDto({
      communityId: saml!.communityId,
      cert: saml!.cert,
      endpoint: saml!.endpoint,
      forceAuthn: !(saml!.forceAuthn)
    }))
  }

  function nameChanged(name: string) {
    setCommunity(new CommunityDto({
      id: community!.id,
      name: name,
      slug: community!.slug
    }))
  }

  function slugChanged(slug: string) {
    setCommunity(new CommunityDto({
      id: community!.id,
      name: community!.name,
      slug: slug
    }))
  }

  return (
    <div>
      <h1>Edit Community {community?.slug}</h1>
      <form>
          <input
            type='text'
            name='name'
            placeholder='Name'
            value={community?.name}
            onChange={(e) => nameChanged(e.target.value)}
          />
          <input
            type='text'
            name='slug'
            placeholder='Slug'
            value={community?.slug}
            onChange={(e) => slugChanged(e.target.value)}
          />
          <input
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              saveCommunity();
            }}
          />
      </form>
      <h2>SSO Settings</h2>
      <form>
          <input
            type='text'
            name='samlCert'
            placeholder='SAML Cert'
            value={saml?.cert}
            onChange={(e) => certChanged(e.target.value)}
          />
          <input
            type='text'
            name='samlEndpoint'
            placeholder='SAML Endpoint'
            value={saml?.endpoint}
            onChange={(e) => endpointChanged(e.target.value)}
          />
          <p>Advanced Settings</p>
          <div>
              <input
                type='checkbox'
                name='samlForceAuthn'
                checked={saml?.forceAuthn}
                onChange={forceAuthnChanged}
              />
              <label>Force Authn</label>
          </div>
          <input type='submit' onClick={(e) => {
            e.preventDefault();
            saveSaml();
          }}/>
      </form>
      <h2>Claimed Domains</h2>
      <ul>
        {domainClaims?.map(claim => {
          return renderClaim(claim);
        })}
      </ul>
    </div>
  );
}
