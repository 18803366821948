import { useNavigate, useParams } from 'react-router-dom';
import { GroupDetailDto } from './ApiClientGenerated';
import { theme } from './Theme';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { api } from './ApiClientFactory'

function GroupDetail() {
  let [group, setGroup] = useState<GroupDetailDto>();
  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    api.showGroup(id!).then(setGroup);
  }, [id]);

  // Note can alternative use node-qrcode, by importing it, then doing something like
  // writing a function that calls QRCode.toDataURL(..) e.g. and then the output of this
  // into a SVG component.

  // For this screen, we might want a button to show the QR code, and then it load full
  // screen with full brightness, and there are some instructions like "Scan the QR
  // code to join the Community: <Community Name Here>"

  return (
    <div style={theme.screen}>
      <h1 style={theme.h1}>{group?.name}</h1>

      <p>Invite Code: {group?.inviteCode
        ? <span style={theme.link} onClick={()=>{ navigate(group?.inviteLink!)}}>{group?.inviteCode}</span>
        : 'None'}
      </p>

      { group?.inviteLink ?
        <QRCode
          size={125}
          value={group?.inviteLink || 'google.com'}
          fgColor='#9EC2F8'
        /> : <></>}

      <h2 style={theme.h2}>Group Members:</h2>
      {group?.members.map((element) => {
        return (
          <p
            key={element.id}
          >
            {element.name} {element.email}
          </p>
        )
      })}

      <h2 style={theme.h2}>Assigned Courses:</h2>
      {group?.assignedCourses.map((element) => {
        return (
          <p
            key={element.id}
            onClick={() => {
              navigate('/courses/' + element.id);
            }}
            style={theme.link}
            >
              {element.name}
          </p>
        )
      })}

      <p><a href={`/groups/${id}/settings`}>Settings</a></p>
    </div>
  );
}

export default GroupDetail;
