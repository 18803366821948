import { useEffect, useState } from "react";
import { Category, CommunityDto } from "./ApiClientGenerated";
import { useNavigate } from "react-router-dom";
import { theme } from "./Theme";
import { api } from './ApiClientFactory'

export function MyCommunities () {
    let [communities, setCommunities] = useState<CommunityDto[]>()

    useEffect(refresh, [])

    const navigate = useNavigate();

    function refresh() {
        listCommunities().catch(console.error);
    }

    async function listCommunities() {
        const response = await api.listCommunities(Category.My);
        setCommunities(response);
    }

    return (
    <ul>
        {communities?.map((element: CommunityDto) => {
          return (
            <li
              key={element.slug}
              onClick={() => {
                navigate('/communities/' + element.id);
              }}
              style={theme.link}>
              {element.name}
            </li>
          )
        })}
    </ul>);
}
