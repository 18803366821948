import { useEffect, useState } from "react";
import { PlaylistDto } from "./ApiClientGenerated";
import { useNavigate } from "react-router-dom";
import { theme } from "./Theme";
import { api } from './ApiClientFactory'

export function MyPlaylists () {
    let [playlists, setPlaylists] = useState<PlaylistDto[]>()

    useEffect(refresh, [])

    const navigate = useNavigate();

    function refresh() {
        listPlaylists().catch(console.error);
    }

    async function listPlaylists() {
        const response = await api.listMyPlaylists();
        setPlaylists(response);
    }

    return (
    <ul>
        {playlists?.map((element: PlaylistDto) => {
            return (
              <li
                key={element.id}
                onClick={() => {
                  navigate('/playlists/' + element.id);
                }}
                style={theme.link}>
                {element.name}
              </li>
            )
          })}
    </ul>);
}
