import { useEffect, useState } from "react";
import { CourseQueueEntryDto } from "./ApiClientGenerated";
import { api } from './ApiClientFactory'

export function CourseQueue() {
  const [courses, setCourses] = useState<CourseQueueEntryDto[]>();

  useEffect(() => {
    load().then((result) => setCourses(result));
  }, [])

  async function load() : Promise<CourseQueueEntryDto[]> {
    return await api.listCourseQueue()
  }

  function firstCourse() : CourseQueueEntryDto | null {
    if (courses && courses.length > 0) {
      return courses[0];
    }

    return null;
  }

  return (
    <div className='course-queue'>
      <h2>Now Learning</h2>
      {<p>{firstCourse()?.courseName ?? ''}</p>}
      <h2>Up Next</h2>
      <input type='text' placeholder='Search' /><i className='fa fa-search'></i>
      <ol>
        {courses?.map((course) => {
          return <li
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/learn?c=' + course.courseId
            }}
            key={course.courseId}
          >
            {course.courseName}
          </li>
        })}
      </ol>
    </div>
  );
}
