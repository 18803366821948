import { useEffect, useState } from "react";
import { CourseDto } from "./ApiClientGenerated";
import { theme } from "./Theme";
import { useNavigate } from "react-router-dom";
import { api } from './ApiClientFactory'

export function MyCourses () {
    let [courses, setCourses] = useState<CourseDto[]>()

    useEffect(refresh, [])

    const navigate = useNavigate();

    function refresh() {
        listCourses().catch(console.error);
    }

    async function listCourses() {
        const response = await api.listMyCourses();
        setCourses(response);
    }

    return (
    <ul>
      {courses?.map((element: CourseDto) => {
        return (
          <li
            key={element.id}
            onClick={() => {
              navigate('/courses/' + element.id);
            }}
            style={theme.link}>
            {element.name}
          </li>
        )
      })}
    </ul>);
}
