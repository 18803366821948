import React from "react";
import { fakeAuthProvider } from "./auth";
import { api } from './ApiClientFactory'
import { SessionCreateRequestDto } from "./ApiClientGenerated";

interface AuthContextType {
  user: string | undefined;
  signin: (username: string, password: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
 }

let AuthContext = React.createContext<AuthContextType>(null!);

// type SessionCreateResultDto = {
//   readonly token: string,
//   readonly failureMessage: string
// }

// async function doFetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
//   return fetch(input, init)
//     .then((response) => {
//       const method = init?.method || 'GET';
//       console.log(`${method} ${input} ${response.status}`);

//       if (init?.body) {
//         console.log('\trequestBody: ' + init.body);
//       }

//       return response;
//     })
//     .catch((error) => {
//       console.error(error);
//       throw error;
//     });
// }

// async function apiSignIn(username: string, password: string): Promise<SessionCreateResultDto> {
//   let response = await doFetch(API_URL + 'session', {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       username: username,
//       password: password,
//     })
//   });

//   return await response.json();
// }

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [token, setToken] = React.useState<string>();

  token = getCookie('ada.loggedIn');

  let signin = (username: string, password: string, callback: VoidFunction) => {

    api.createSession(new SessionCreateRequestDto({username, password})).then((res) => {
      setToken(res.token);
      document.cookie = `ada.loggedIn=true`
      // document.cookie = `ada.sessionToken=${res.token};SameSite=none;Secure`//;max-age=604800;domain=example.com`
      callback();
    })
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(undefined);
      callback();
    });
  };

  let value: AuthContextType = { user: token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()!.split(';').shift();
}

export function useAuth() {
  return React.useContext(AuthContext);
}
