import { useNavigate, useParams } from 'react-router-dom';
import { theme } from './Theme';
import { useEffect, useState } from 'react';
import { CommunitySummaryDto } from './ApiClientGenerated';
import { api } from './ApiClientFactory'

function CommunityDetail() {
  let [summary, setSummary] = useState<CommunitySummaryDto>();
  let { slug } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    api.showCommunity(slug!).then(setSummary);
  }, [slug]);

  return (
    <div style={theme.screen}>
      <h1 style={theme.h1}>{summary?.community.name}</h1>

      <h2 style={theme.h2}>Groups:</h2>
      {summary?.groups?.map((element) => {
        return (
          <p
            style={theme.link}
            key={element.id}
            onClick={() => navigate('/groups/' + element.id)}
          >
            {element.name}
          </p>
        )
      })}
      <p><a href='/groups/new'>Create New</a></p>

      <h2 style={theme.h2}>Members:</h2>
      {summary?.members?.map((element) => {
        return (<p key={element.id}>{element.name} {element.email}</p>)
      })}

      <h2 style={theme.h2}>Courses:</h2>
      {summary?.courses?.map((element) => {
        return (
          <p key={element.id}>
            <span
              onClick={() => {
                navigate('/courses/' + element.id);
              }}
              style={theme.link}
              >
                {element.name}
            </span>
            <span> </span>
            (<span
              onClick={() => {
                navigate(`/courses/${element.id}/edit`)
              }}
              style={theme.link}
            >
              edit
          </span>)
        </p>
        )
      })}
      <p><a href='/courses/new'>Create New</a></p>

      <p><a href={`/communities/${slug}/edit`}>Settings</a></p>
    </div>
  );
}

export default CommunityDetail;
