export function NavBar() {
    return (
        <div className='navbar'>
            <ul>
                <li><a href='/learn'><i className='fas fa-book'></i><span>Learn</span></a></li>
                <li><a href='/'><i className='fas fa-compass'></i><span>Explore</span></a></li>
                <li><a href='/teach'><i className='fas fa-plus'></i><span>Teach</span></a></li>
                <li><a href='/communities'><i className='fas fa-users'></i><span>Communities</span></a></li>
                <li><a href='/me'><i className='fas fa-user'></i><span>Me</span></a></li>
            </ul>
        </div>);
}