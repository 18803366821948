const API_URL = process.env.API_URL ?? 'https://localhost:7222/v1/';
const MEDIA_URL = process.env.MEDIA_URL ??'https://localhost:7222/';

export function apiBaseUrl() : string {
  return API_URL;
}

export function mediaUrl() : string {
  return MEDIA_URL;
}
