import { theme } from './Theme';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from './ApiClientFactory'
import { GroupCreateRequestDto } from './ApiClientGenerated';

export function NewGroup() {
  const [name, setName] = React.useState('');

  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  async function create() {
    console.log('search param ' + JSON.stringify(searchParams))
    const communityId = searchParams.get('communityId')
    void await api.createGroup(new GroupCreateRequestDto({name, communityId: communityId!}));
    navigate(-1);
  }

  return (
    <div style={theme.screen}>
      <h1>New Group</h1>
      <form>
        <input
          type='text'
          placeholder="Group Name"
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <input type='button' value="Submit" onClick={create} />
        </div>
      </form>
    </div>
  );
}
