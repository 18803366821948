import * as React from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Logo } from "./Logo";

export function LoginPage() {

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("email") as string;
    let password = formData.get("password") as string;

    auth.signin(username, password, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  }

  return (
    <div className='login'>
      <div className='login-item'>
        <div>
          <Logo />
        </div>
        <div className="login-prompt">
          <form method='post' onSubmit={handleSubmit}>
            <input type='email' name='email' placeholder='Email' autoComplete='username' spellCheck='false' autoCapitalize='none' required />
            <input type='password' name='password' placeholder='Password' autoComplete='current-password' spellCheck='false' autoCapitalize='off' required />
            <input type='submit' value='Sign in' />
          </form>
        </div>
        <div className="login-instructions">
          <p style={{ textAlign: 'center' }}>Don't have an account? <a href='/users/new'>Sign up</a></p>
          <p style={{ textAlign: 'center' }}><a href='/forgotPassword'>Forgot Password</a></p>
        </div>
      </div>
    </div>);
}
