import { WrittenContentDto } from '../ApiClient';
import { NextContentType } from '../types';

export type WrittenProps = {
    content: WrittenContentDto,
    onNext: NextContentType
}

const WrittenInstruction = ({content, onNext}: WrittenProps) => {

  function resultData() {
    return { }
  }

  return (
    <div
      className="written-content"
      style={{
        fontSize: content.textSize ?? 24,
        color: content.textColor ?? 'black',
        backgroundColor: content.backgroundColor ?? 'white'
      }}
      onClick={() => onNext(resultData())}
      >
      <p>{content.text}</p>
    </div>
  );
}

export default WrittenInstruction;
