export const theme = {
  screen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  h1: {
    fontSize: 40,
    margin: 10
  },
  h2: {
    fontSize: 30,
    margin: 10
  },
  link: {
    color: 'purple',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};
