import { theme } from './Theme';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './ApiClientFactory'
import { PlaylistCreateRequestDto } from './ApiClientGenerated';

export function NewPlaylist() {
  const [name, setName] = React.useState('');
  const [isPublic, setIsPublic] = React.useState(true);

  let navigate = useNavigate();

  async function create() {
    await api.createPlaylist(new PlaylistCreateRequestDto({name, isPublic}));
    navigate(-1);
  }

  return (
    <div style={theme.screen}>
      <h1>New Playlist</h1>
      <form>
        <input
          type='text'
          placeholder="Playlist Name"
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <label>
            <input
              type="checkbox"
              checked={isPublic}
              onChange={(e) => setIsPublic(!isPublic) }
            />
            Is Public?
          </label>
        </div>
        <div>
          <input type='button' value="Submit" onClick={create} />
        </div>
      </form>
    </div>
  );
}
