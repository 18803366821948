import { useState } from "react";
import { WrittenContentDto, apiUpdateContent } from "../ApiClient";
import { useDebounce } from "../hooks/useDebounce";

export function WrittenInstructionEditor({content, onDelete} : {content: WrittenContentDto, onDelete: () => void}) {
  let [backgroundColor, setBackgroundColor] = useState<string>(content.backgroundColor);
  let [textColor, setTextColor] = useState<string>(content.textColor);
  let [textSize, setTextSize] = useState<number>(content.textSize);
  let [text, setText] = useState<string>(content.text);

  const save = useDebounce(() => {
    void apiUpdateContent(content.contentId, {
      text: text,
      textSize: textSize,
      textColor: textColor,
      backgroundColor: backgroundColor
    }).catch(console.error);
  });

  return (
    <div className="content-editor">
      <div className="content-rendering"
        style={{ backgroundColor: backgroundColor }}>
      <p
        className="preview-text"
        style={{ fontSize: textSize + 'px', color: textColor }}>
        {text}
      </p>
      </div>
      <div className="content-options">
        <form>
          <label>Background Color</label>
          <input type="color" value={backgroundColor} onChange={(e) => {
            setBackgroundColor(e.target.value);
            save();
          }} />
          <label>Text Color</label>
          <input type="color" value={textColor} onChange={(e) => {
            setTextColor(e.target.value);
            save();
          }} />
          <label>Text Size</label>
          <input type="number" value={textSize} onChange={(e) => {
            setTextSize(Number(e.target.value));
            save();
          }} />
          <label>Text</label>
          <textarea value={text} onChange={(e) => {
            setText(e.target.value);
            save();
          }}></textarea>
          <button onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}>
            Delete
          </button>
        </form>
      </div>
    </div>)
}
