import { useState } from 'react';
import { NextContentType } from '../types';
import { MultipleChoiceContentDto } from '../ApiClient';
import { playCorrect } from '../SoundEffects';

type MultipleChoiceProps = {
    content: MultipleChoiceContentDto,
    onNext: NextContentType
  }

const MultipleChoice = ({content, onNext}: MultipleChoiceProps) => {
  const [state, setState] = useState('')
  const [answer, setAnswer] = useState(-1)

  const renderAnswer = () => {
    let isCorrect = content.correctChoiceIndex === answer

    if (isCorrect) {
      void playCorrect()
    }

    let result = {
      isCorrect: isCorrect,
      isSkipped: false,
      isDontKnow: false
    }

    let text = isCorrect
      ? 'Correct! 🎉'
      : 'Oops, the correct answer is: '  + content.choices[content.correctChoiceIndex];

    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => onNext(result)}>
          <p
            style={{
              padding: 25,
              fontSize: 24,
              textAlign: 'center'
            }}
          >
            {text}
          </p>
      </div>
    );
  }

  function chooseIndex(index: number) {
    setAnswer(index);
    setState('submit_answer');
  }

  function renderAssesment() {
    return (
      <div>
        <p
          style={{
            padding: 20,
            // fontSize: content.textSize,
            // color: content.textColor,
            textAlign: 'center',
          }}
        >
          {content.prompt}
        </p>
        {content.choices.map((element: string, index: number) => {
          return (<p key={index} onClick={() => chooseIndex(index)}>{element}</p>)
        })}
      </div>
    );
  }

  const render = () => {
    if (state === 'submit_answer') {
      return renderAnswer();
    } else {
      return renderAssesment();
    }
  }

  return render();
}

export default MultipleChoice;
