import { MyCommunities } from "./MyCommunities";

export function Communities() {
  return (
    <div>
      <h2>Your Communities</h2>
      <MyCommunities />
      <h2>Recommended Communities</h2>
      <h2>Popular Communities</h2>
      <h2>Trending Communities</h2>
    </div>
  );
}
