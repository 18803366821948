import { useEffect, useState } from "react";
import { CourseDetailDto, WrittenContentDto, apiCreateContent, apiGetCourse } from "./ApiClient";
import { useNavigate, useParams } from "react-router-dom";
import { WrittenInstructionEditor } from "./Editors/WrittenInstructionEditor";
import { api } from './ApiClientFactory'

export function CourseEdit() {
    let [course, setCourse] = useState<CourseDetailDto>();
    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        apiGetCourse(id!).then(setCourse).catch(console.error);
    }, [id]);

    async function removeContent(contentId: string) {
        void await api.deleteContent(contentId);

        // TODO: call API to delete
        // Do this here vs. in the editor since it will be the same for all content editors
        setCourse({
            id: course!.id,
            isPublic: course!.isPublic,
            name: course!.name,
            content: course!.content.filter(c => c.contentId !== contentId)
        })
    }

    async function addWrittenInstruction() {
        const addend = await apiCreateContent(course?.id)

        setCourse({
            id: course!.id,
            isPublic: course!.isPublic,
            name: course!.name,
            content: [...course!.content, addend]
        })
    }

    return (
        <div>
            <span
                className='course-editor-exit'
                onClick={() => { navigate(-1) }}
            >
                [X]
            </span>
            <div className="course-editor-header">
                <form>
                    <input type="text" name='courseName' defaultValue={course?.name ?? "Course Name"} />
                </form>
            </div>
            <div className="course-editor-content">
                { course?.content.map(c => {
                    if (c.contentType === "WrittenInstruction") {
                        return <WrittenInstructionEditor
                            key={c.contentId}
                            content={c as WrittenContentDto}
                            onDelete={() => removeContent(c.contentId) } />;
                    } else {
                        return <p>Unknown content type.</p>
                    }
                })}
            </div>
            <div className="content-editor-add-content">
                <form>
                    <button onClick={(e) => {
                        e.preventDefault();
                        addWrittenInstruction();
                    }}>
                        Add content
                    </button>
                </form>
            </div>
        </div>);
}
