import { apiBaseUrl } from './ConfigService';

export type ContentResultDto = {
  contentId: string,
  contentType: string,
  contentData: object | null
}

export type CourseDto = {
  readonly id: string,
  name: string
}

export type CourseDetailDto = {
  readonly id: string,
  name: string,
  isPublic: boolean,
  content: IContentDto[]
}

export enum ContentTypeDto {
  Assessment = 'Assessment',
  Achievement = 'Achievement',
  MultipleChoice = 'MultipleChoice',
  VideoInstruction = 'VideoInstruction',
  WrittenInstruction = 'WrittenInstruction'
}

export interface IContentDto {
  readonly contentId: string,
  contentType: ContentTypeDto
}

export type WrittenContentDto = IContentDto & {
  text: string,
  textSize: number,
  textColor: string,
  backgroundColor: string
}

export type AchievementContentDto = IContentDto & {
  name: string,
  displayText: string,
}

export type VideoContentDto = IContentDto & {
  mediaId: string,
  isPublic: boolean
}

export type AssessmentContentDto = IContentDto & {
  prompt: string,
  correctAnswer: string
}

export type MultipleChoiceContentDto = IContentDto & {
  prompt: string,
  choices: string[],
  correctChoiceIndex: number
}

export async function apiSaveContentResult(contentResult: ContentResultDto): Promise<void> {
  var response = await authenticatedCall('content-results', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(contentResult)
  });

  if (response.status !== 201) {
    throw Error('Unable to save content result.');
  }
}

export async function apiUpdateContent(contentId: string, updates: { text: string, textSize: number, textColor: string, backgroundColor: string }) {
  let response = await authenticatedCall('content/' + contentId, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updates)
    });

    if (response.status !== 204) {
      throw Error('Unable to save content.');
    }
}

export async function apiGetCourse(id: string) : Promise<CourseDetailDto> {
  let response = await authenticatedCall('courses/' + id, {
      headers: {
        'Accept': 'application/json'
      }
    });

  return await response.json();
}

export async function apiCreateCourse(name: string, isPublic: boolean, communityId?: string) : Promise<CourseDto> {
  let response = await authenticatedCall('courses', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: name,
      isPublic: isPublic,
      communityId: communityId
    })
  });

  return await response.json();
}

export async function apiCreateContent(courseId?: string) : Promise<WrittenContentDto> {
  let response = await authenticatedCall(`content?courseId=${courseId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json'
    }
  });

  return response.json();
}

export async function apiCreatePassword(currentPassword: string, newPassword: string, confirmPassword: string) : Promise<void> {
  let response = await authenticatedCall('my/passwords/new', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    })
  });

  if (response.status !== 201) {
    throw Error('Unable to change password.');
  }
}

async function authenticatedCall(path: string, opts: RequestInit): Promise<Response> {
//   const token = await getSessionToken();

//   let h = opts.headers as {[key: string]: string}
//   h['Cookie'] = 'ada.sessionToken=' + token;

  return doFetch(apiBaseUrl() + path, opts);
}

async function doFetch(input: RequestInfo, init: RequestInit): Promise<Response> {
  // This needs to be set to true for the cookie to be sent, but ALSO
  // for the Set-Cookie in response to succeed.  This surprising behavior
  // cost us a lot of time.
  init.credentials = 'include'

  return fetch(input, init)
    .then((response) => {
      const method = init?.method || 'GET';
      console.log(`${method} ${input} ${response.status}`);

      if (init?.body) {
        console.log('\trequestBody: ' + init.body);
      }

      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
