import { useEffect, useState } from "react";
import { CourseDto, ExploreSectionDto } from "./ApiClientGenerated";
import { theme } from "./Theme";
import { useNavigate } from "react-router-dom";
import { api } from './ApiClientFactory'

export function Explore () {
  let [exploreSections, setExploreSections] = useState<ExploreSectionDto[]>();

  useEffect(refresh, [])

  let navigate = useNavigate();

  function refresh() {
    getExploreSections().catch(console.error);
  }

  async function getExploreSections() {
    const response = await api.listExploreSections();
    setExploreSections(response);
  }

  function renderSection(section: ExploreSectionDto) : React.JSX.Element {
    return (
      <div key={section.sectionName}>
        <h2 style={theme.h2}>{section.sectionName}</h2>
        {section.courses?.map((course: CourseDto) => {
          return (
            <p key={course.id}>
              <span
                style={theme.link}
                onClick={() => navigate('/learn?c=' + course.id)}
              >
                {course.name}
              </span>
              <LearnLaterButton courseId={course.id} />
            </p>
          )
        })}
      </div>
    );
  }

  return (
    <div style={theme.screen}>
      <h1 style={theme.h1}>Explore</h1>
      {exploreSections?.map((section: ExploreSectionDto) => {
        return renderSection(section)
      })}
    </div>
  );
}

type LearnLaterProps = {
  courseId: string
}

function LearnLaterButton({courseId} : LearnLaterProps) {
  let [added, setAdded] = useState(false);

  async function addToLearnLater(courseId: string) {
    await api.createMyLearnLaterCourse(courseId);
    setAdded(true)
  }

  function render() {
    if (added) {
      return <span>✓</span>
    } else {
      return (
        <span
          style={theme.link}
          onClick={() => addToLearnLater(courseId)}
        >
          [+]
        </span>
      )
    }
  }

  return render();
}