import * as React from "react";
import { IdentityDto } from "./ApiClientGenerated";
import { MyPlaylists } from "./MyPlaylists";
import { MyCommunities } from "./MyCommunities";
import { MyCourses } from "./MyCourses";
import { theme } from "./Theme";
import { api } from './ApiClientFactory'

export function MePage() {
  React.useEffect(refresh, [])

  let [identity, setIdentity] = React.useState<IdentityDto>();

  function refresh() {
    getMyIdentity().catch(console.error);
  }

  async function getMyIdentity() {
    const response = await api.getMyIdentity()
    setIdentity(response);
  }

  async function signOut() {
    const session = await api.showSession();
    await api.deleteSession(session!.sessionId);
    document.cookie = `ada.loggedIn=`
    window.location.href = '/';
  }

  return (
    <div>
      <h1>Hello, {identity?.name}!</h1>

      {/* <h2>Enrollments 🏫</h2>
      <ul>
          <li>French Colors 10/31/2023 05:09:59 +00:00 9%</li>
      </ul>
      <p><a href='/'>Find new courses!</a></p> */}

      <h2>Playlists ▶️</h2>
      <MyPlaylists />
      <p><a href='/playlists/new?personal=true'>Create New</a></p>

      <h2>Communities 👥</h2>
      <MyCommunities />
      <p><a href='/communities/new?personal=true'>Create New</a></p>

      <h2>Courses 📚</h2>
      <MyCourses />
      <p><a href='/courses/new?personal=true'>Create New</a></p>

      <h2>Achievements 🏆</h2>
      <ul>
          <li>Two day learning streak!</li>
          <li>Completed "Welcome to Ada"</li>
      </ul>

      <h2>Rankings 📈</h2>
      <ul>
          <li>Top 10% in Math in Your Region</li>
          <li>Top 5% in CSS in Your Region</li>
          <li>Bottom 30% in CSS in Your Region</li>
      </ul>

      <h2>Career Skills 🤑</h2>
      <ul>
          <li>Critical thinking: TBD (<span style={theme.link}>Assess me now!</span>)</li>
          <li>English Proficiency: TBD (<span style={theme.link}>Assess me now!</span>)</li>
          <li>CSS: TBD (<span style={theme.link}>Assess me now!</span>)</li>
          <li>JavaScript: TBD (<span style={theme.link}>Assess me now!</span>)</li>
          <li>I am open to work: Y/N</li>
          <li>Location: TBD</li>
      </ul>

      <h3>Settings</h3>
      <ul>
          <li><span style={theme.link} onClick={signOut}>Sign out</span></li>
          <li><a href='/me/password/new'>Change password</a></li>
      </ul>
    </div>
  );
}
