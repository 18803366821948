import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

// Source: https://www.developerway.com/posts/debouncing-in-react
export const useDebounce = (callback: () => void) => {
  // creating ref and initializing it with the callback function
  const ref = useRef(callback);

  useEffect(() => {
    // updating ref when state changes
    // now, ref.current will have the latest sendRequest with access to the latest state
    ref.current = callback;
  }, [callback]);

  // creating debounced callback only once - on mount
  const debouncedCallback = useMemo(() => {
    // func will be created only once - on mount
    const func = () => {
      // ref is mutable! ref.current is a reference to the latest sendRequest
      ref.current?.();
    };

    // debounce the func that was created once, but has access to the latest sendRequest
    return debounce(func, 1000);

    // no dependencies! never gets updated
  }, []);

  return debouncedCallback;
}
