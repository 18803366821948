import { theme } from './Theme';
import React from 'react';
import { apiCreateCourse } from './ApiClient';
import { useNavigate } from 'react-router-dom';

export function NewCourse() {
  const [name, setName] = React.useState('');
  const [isPublic, setIsPublic] = React.useState(true);

  let navigate = useNavigate();

  async function create() {
    await apiCreateCourse(name, isPublic);
    navigate(-1);
  }

  return (
    <div style={theme.screen}>
      <h1>New Course</h1>
      <form>
        <input
          type='text'
          placeholder="Course Name"
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <label>
            <input
            type="checkbox"
              checked={isPublic}
              onChange={(e) => setIsPublic(!isPublic) }
            />
            Is Public?
          </label>
        </div>
        <div>
          <input type='button' value="Submit" onClick={create} />
        </div>
      </form>
    </div>
  );
}
