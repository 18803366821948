import { useEffect, useState } from "react";
import { AchievementContentDto, AssessmentContentDto, ContentResultDto, MultipleChoiceContentDto, VideoContentDto, WrittenContentDto } from "./ApiClient";
import WrittenInstruction from "./Content/WrittenInstruction";
import Achievement from "./Content/Achievement";
import MultipleChoice from "./Content/MultipleChoice";
import { useSearchParams } from "react-router-dom";
import { Assessment } from "./Content/Assessment";
import VideoInstruction from "./Content/VideoInstruction";
import { CourseQueue } from "./CourseQueue";
import { api } from './ApiClientFactory'
import { LearningEngineStateDto, NewLearningEngineResultDto } from "./ApiClientGenerated";

export function Learn() {
  let [content, setContent] = useState<LearningEngineStateDto>();

  let [search] = useSearchParams();

  useEffect(() => {
    const c = search.get('c');

    api.learningEngineGetNext(c ?? undefined)
      .then(nextContent => {
        setContent(nextContent)
      });
  }, [search])

  async function next(contentData?: {}): Promise<void> {
    // if (contentData) {
    //   // Future: it probably doesn't make sense to ask the client to do this.
    //   contentData.facts = content.facts;
    // }

    const contentResult: ContentResultDto = {
      contentId: content?.nextContent.contentId,
      contentType: content?.nextContent.contentType,
      contentData: contentData ?? null
    }

    // FUTURE: combine saveContentResult and getNextContent into a single server call.

    if (contentData) {
      await api.createLearningEngineResult(new NewLearningEngineResultDto(contentResult));
    }

    const c = search.get('c');
    const nextContent = await api.learningEngineGetNext(c ?? undefined);
    setContent(nextContent);
  }

  function renderContent() {
    if (content === undefined || content.contentType === '') {
      // FUTURE: Show some loading screen if it takes too long, but if show it ensure it's not a flash (show it for some minimum time)
      return;
      // return <Text>"Loading..."</Text>;
    }

    // I might be able to remove key now that content is being passed as a prop to the controls?
    // I guess the question is when does the react useState values get reset.

    var c = content;
    if (c.nextContent.contentType === 'VideoInstruction') {
      return <VideoInstruction content={c.nextContent as VideoContentDto} onNext={next} key={c.nextContent.contentId} />
    } else if (c.nextContent.contentType === 'WrittenInstruction') {
      return <WrittenInstruction content={c.nextContent as WrittenContentDto} onNext={next} key={c.nextContent.contentId} />
    } else if (c.nextContent.contentType === 'Assessment') {
      // TODO: key should actually be the ID of the instance of the content
      // Otherwise, it's possibly we will serve the same content twice and then the control will be reused.
      // Regardless, we'll want some record of when we served the content; probably.
      return <Assessment content={c.nextContent as AssessmentContentDto} onNext={next} key={c.nextContent.contentId} />
    } else if (c.nextContent.contentType === 'Achievement') {
      return <Achievement content={c.nextContent as AchievementContentDto} onNext={next} key={c.nextContent.contentId} />
    } else if (c.nextContent.contentType === 'MultipleChoice') {
      return <MultipleChoice content={c.nextContent as MultipleChoiceContentDto} onNext={next} key={c.nextContent.contentId} />
    } else {
      // We probably want to actually skip this content.  We can tell the server, perhaps, that
      // we the client are skipping it because we don't understand it.
      return <p>{JSON.stringify(c)}</p>;
    }
  }

  return (
    <div>
      <div className='learn-rendering'>
        { renderContent() }
      </div>
      <CourseQueue />
    </div>
  );
}
