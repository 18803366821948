import { Navigate, Outlet, useLocation } from "react-router-dom";
import { NavBar } from "./Navbar";
import { useAuth } from "./AuthProvider";

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  }

export function ProtectedLayout() {
    return (
      <RequireAuth>
        <div>
          <NavBar />
          <div>
            <Outlet />
          </div>
        </div>
      </RequireAuth>
    );
}

export function ProtectedNakedLayout() {
  return (
    <RequireAuth>
      <div>
          <Outlet />
      </div>
    </RequireAuth>
  );
}
