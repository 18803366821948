import {
  Routes,
  Route} from "react-router-dom";
import { AuthProvider } from "./AuthProvider";
import { ProtectedLayout, ProtectedNakedLayout } from "./ProtectedLayout";
import { MePage } from "./MePage";
import { Communities } from "./Communities";
import { Learn } from "./Learn";
import { Explore } from "./Explore";
import { Teach } from "./Teach";
import { LoginPage } from "./LoginPage";
import { PublicPage } from "./PublicPage";
import { PlaylistDetail } from "./PlaylistDetail";
import { NewPlaylist } from "./NewPlaylist";
import { NewCourse } from "./NewCourse";
import { NewCommunity } from "./NewCommunity";
import CommunityDetail from "./CommunityDetail";
import { NewGroup } from "./NewGroup";
import GroupDetail from "./GroupDetail";
import { CourseEdit } from "./CourseEdit";
import { NewPasswordPage } from "./NewPasswordPage";
import { CommunityEditPage } from "./CommunityEditPage";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/public" element={<PublicPage />} />
          <Route element={<ProtectedLayout />}>
            <Route path="/learn" element={<Learn />} />
            <Route path="/" element={<Explore />} />
            <Route path="/teach" element={<Teach />} />
            <Route path="/communities" element={<Communities />} />
            <Route path="/me" element={<MePage />} />

            <Route path="/playlists/new" element={<NewPlaylist />} />
            <Route path="/playlists/:id" element={<PlaylistDetail />} />

            <Route path="/courses/new" element={<NewCourse />} />

            <Route path="/communities/new" element={<NewCommunity />} />
            <Route path="/communities/:slug/edit" element={<CommunityEditPage />} />
            <Route path="/communities/:slug" element={<CommunityDetail />} />

            <Route path="/groups/new" element={<NewGroup />} />
            <Route path="/groups/:id" element={<GroupDetail />} />

            <Route path="/me/password/new" element={<NewPasswordPage />} />
          </Route>
        </Route>
        <Route element={<ProtectedNakedLayout />}>
          <Route path="/courses/:id/edit" element={<CourseEdit />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
