import { useState } from "react";
import { apiCreatePassword } from "./ApiClient";

export function NewPasswordPage() {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  async function submit() {
    await apiCreatePassword(currentPassword, newPassword, confirmPassword);
  }

  return (
    <div>
      <h1>New Password</h1>
      <form>
        <input
          type='password'
          placeholder='Current Password'
          required
          onChange={(e) => {
            e.preventDefault();
            setCurrentPassword(e.target.value);
          }}
        />
        <input
          type='password'
          placeholder='New Password'
          minLength={8}
          autoComplete="new-password"
          required
          onChange={(e) => {
            e.preventDefault();
            setNewPassword(e.target.value);
          }}
        />
        <input
          type='password'
          placeholder='Confirm New Password'
          minLength={8}
          autoComplete="new-password"
          required
          onChange={(e) => {
            e.preventDefault();
            setConfirmPassword(e.target.value);
          }}
        />
        <input
          type='submit'
          onClick={(e) => {
            e.preventDefault();
            submit();
          }}
        />
      </form>
    </div>);
}
