import { theme } from './Theme';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CourseDto, PlaylistDetailDto } from './ApiClientGenerated';
import { api } from './ApiClientFactory'

export function PlaylistDetail() {
  let [playlist, setPlaylist] = useState<PlaylistDetailDto>();

  useEffect(() => {
    getPlaylist().catch(console.error);
  });

  let navigate = useNavigate();
  let { id } = useParams();

  async function getPlaylist() {
    const response = await api.showPlaylist(id!); // FUTURE: get rid of "!" cludge
    setPlaylist(response);
  }

  async function deletePlaylist() {
    await api.deletePlaylist(id!); // FUTURE: get rid of "!" cludge
    navigate(-1); // FUTURE: notify prior screen to refesh
  }

  async function removeCourse(courseId: string) {
    if (playlist == null) {
      return;
    }

    await api.deletePlaylistCourse(playlist.id, courseId);
    await getPlaylist();
  }

  return (
    <div style={theme.screen}>
      <h1 style={theme.h1}>{playlist?.name}</h1>

      <h2 style={theme.h2}>Courses 📚</h2>
      {playlist?.courses.map((element: CourseDto) => {
        return (
          <p key={element.id}>
            <span
              onClick={() => navigate('/courses/' + element.id)}
              style={theme.link}
            >
              {element.name}
            </span>
            <span
              onClick={() => { removeCourse(element.id) }} style={theme.link}>
                X
            </span>
          </p>
        )
      })}

      { !playlist?.isSystem ?  <p title='Delete' style={theme.link} onClick={deletePlaylist} /> : ''  }
    </div>
  );
}
