import { AchievementContentDto } from "../ApiClient";
// import { playAchievement } from "../SoundEffects";
import { NextContentType } from "../types";

type AchievementProps = {
  content: AchievementContentDto,
  onNext: NextContentType
}

const Achievement = ({content, onNext} : AchievementProps) => {

  function resultData() {
    return { }
  }

  // void playAchievement()

  return (
    <div onClick={() => onNext(resultData())} style={{ cursor: 'pointer' }}>
        <p
          style={{
            padding: 20,
            fontSize: 42,
            // color: content.textColor,
            textAlign: 'center',
            // backgroundColor: content.backgroundColor
            // overflowWrap: 'anywhere'
          }}
        >
          {content.displayText}
        </p>
        <p style={{
            padding: 20,
            fontSize: 42,
            // color: content.textColor,
            textAlign: 'center',
            // backgroundColor: content.backgroundColor
            // overflowWrap: 'anywhere'
          }}>🏆</p>
    </div>
  );
}

export default Achievement;
