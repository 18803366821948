import { VideoContentDto } from '../ApiClient';
import { mediaUrl } from '../ConfigService';
import { NextContentType } from '../types';

type VideoProps = {
  content: VideoContentDto,
  onNext: NextContentType
}

const VideoInstruction = ({content, onNext}: VideoProps) => {
  function resultData() {
    return {
      // fact: content.facts
    }
  }

  function unmute() {
    let video = document.getElementById('content') as HTMLVideoElement;
    video.muted = false;
  }

  // Calling onNext if the user clicks on the video makes it look like they completed the video

  return (
    <div className='video-container' onClick={() => onNext(resultData())}>
      <div className='video-content'>
          <video
            id='content'
            width='390'
            height='844'
            style={{ objectFit:'cover' }}
            playsInline
            muted
            autoPlay
            onEnded={() => onNext(resultData())}
          >
            <source src={mediaUrl() + `media/${content.mediaId}`} type='video/mp4' />
          </video>
      </div>
      <div className='video-controls'>
          <button id='unmuteButton' onClick={unmute}>Unmute</button>
          <form method='post'>
              <input type='submit' id='submit' value='Next' onClick={(e) => {
                e.preventDefault();
                onNext(resultData());
              }} />
          </form>
      </div>
    </div>
  );
}

export default VideoInstruction;
